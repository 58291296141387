@import "../utils/vars";

$transitionTime: 0.5s;

app-admin-dashboard,
app-attachments-employee,
app-documents-history,
app-patients,
app-patients-docs,
app-red-flag-application,
app-incidents,
app-incidents-attachments,
app-patients-incident-report,
app-admissions,
app-admission-attachments,
app-patients-admission-docs,
app-patient-doc-period,
app-timesheets,
app-red-flags,
app-patients-red-flags,
app-admission-accuracy-reports-page,
app-admission-incomplete-reports-page,
app-education,
app-plan-details,
app-user-management,
app-new-timesheet,
app-medical,
app-attachment-medical,
app-medical-documents-history,
app-mandatory-attachments-report,
app-incident-accuracy-report,
app-employee-daily-report,
app-timesheets-daily-report {
  app-multiselect {
    .list {
      &__drop {
        display: block !important;
        max-height: 0;
        opacity: 0;
        transition: all ease $transitionTime;
        overflow: hidden;

        &.with-search {
          .search-wrap,
          .search-select {
            transition: height ease $transitionTime;
          }
        }

        &.without-animation {
          transition: initial;
        }

        &.show {
          opacity: 1;
          max-height: 350px;
        }
      }
    }
  }

  .by-date {
    .flatpickr-calendar {
      &.inline {
        display: block;
        height: 0;
        opacity: 0;
        transition: all ease $transitionTime;
        overflow: hidden;
      }
    }

    &.showCalendar {
      .flatpickr-calendar {
        &.inline {
          height: 335px !important;
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    app-multiselect {
      .list {
        &__drop {
          min-height: initial !important;
        }
      }
    }
  }
}

app-new-timesheet {
  app-multiselect {
    .list {
      &__drop {
        display: block !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        position: static !important;
        top: initial !important;
        left: inherit !important;
        padding: 0 0 0 0 !important;

        &.with-search {
          .search-wrap,
          .search-select {
            transition: height ease $transitionTime;
          }

          .search-wrap {
            padding-left: 0;
            padding-right: 0;

            input {
              width: 100%;
              @media screen and (max-width: 400px) {
                width: calc(100% - 10px);
              }
            }
          }
        }
      }

      .form-group {
        label {
          padding: 8px 10px 8px 0 !important;
        }

        input {
          &:checked {
            + label {
              &:after {
                left: 6px !important;
              }
            }
          }
        }
      }

      .radio-item {
        label {
          padding-left: 0 !important;

          &:after {
            left: 5px !important;
          }
        }
      }
    }
  }

  .flatpickr-calendar {
    &.inline {
      display: block !important;
    }
  }

  .by-date {
    .flatpickr-calendar {
      &.inline {
        height: 0;
        opacity: 0;
        transition: all ease $transitionTime;
        overflow: hidden;
        position: relative;
        left: -5px;
      }
    }

    &.showCalendar {
      .flatpickr-calendar {
        &.inline {
          height: 335px !important;
          opacity: 1;
        }
      }
    }
  }
}

// 2250
app-admissions {
  @media screen and (max-width: 2250px) {
    app-multiselect {
      .list {
        &__drop {
          display: block !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          position: static !important;
          top: initial !important;
          left: inherit !important;
          padding: 0 0 0 0 !important;

          &.with-search {
            .search-wrap,
            .search-select {
              transition: height ease $transitionTime;
            }

            .search-wrap {
              padding-left: 0;
              padding-right: 0;

              input {
                width: 100%;
                @media screen and (max-width: 400px) {
                  width: calc(100% - 10px);
                }
              }
            }
          }
        }

        .form-group {
          label {
            padding: 8px 10px 8px 0 !important;
          }

          input {
            &:checked {
              + label {
                &:after {
                  left: 6px !important;
                }
              }
            }
          }
        }

        .radio-item {
          label {
            padding-left: 0 !important;

            &:after {
              left: 5px !important;
            }
          }
        }
      }
    }

    .flatpickr-calendar {
      &.inline {
        display: block !important;
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          height: 0;
          opacity: 0;
          transition: all ease $transitionTime;
          overflow: hidden;
          position: relative;
          left: -10px;
        }
      }

      &.showCalendar {
        .flatpickr-calendar {
          &.inline {
            height: 335px !important;
            opacity: 1;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1420px) {
    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -5px;
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    app-multiselect {
      .list {
        &__drop {
          &.with-search {
            .search-wrap {
              input {
                width: calc(100% - 10px);
              }
            }
          }
        }
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -11px;
        }
      }
    }
  }
}

// 2100
app-admin-dashboard,
app-patients-red-flags,
app-red-flags {
  @media screen and (max-width: 2100px) {
    app-multiselect {
      .list {
        &__drop {
          display: block !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          position: static !important;
          top: initial !important;
          left: inherit !important;
          padding: 0 0 0 0 !important;

          &.with-search {
            .search-wrap,
            .search-select {
              transition: height ease $transitionTime;
            }

            .search-wrap {
              padding-left: 0;
              padding-right: 0;

              input {
                width: 100%;
                @media screen and (max-width: 400px) {
                  width: calc(100% - 10px);
                }
              }
            }
          }
        }

        .form-group {
          label {
            padding: 8px 10px 8px 0 !important;
          }

          input {
            &:checked {
              + label {
                &:after {
                  left: 6px !important;
                }
              }
            }
          }
        }

        .radio-item {
          label {
            padding-left: 0 !important;

            &:after {
              left: 5px !important;
            }
          }
        }
      }
    }

    .flatpickr-calendar {
      &.inline {
        display: block !important;
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          height: 0;
          opacity: 0;
          transition: all ease $transitionTime;
          overflow: hidden;
          position: relative;
          left: -10px;
        }
      }

      &.showCalendar {
        .flatpickr-calendar {
          &.inline {
            height: 335px !important;
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1420px) {
    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -5px;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    app-multiselect {
      .list {
        &__drop {
          &.with-search {
            .search-wrap {
              input {
                width: calc(100% - 10px);
              }
            }
          }
        }
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -11px;
        }
      }
    }
  }
}

// 1800
app-incidents,
app-patients-incident-report,
app-patients-admission-docs,
app-patient-doc-period {
  @media screen and (max-width: 1800px) {
    app-multiselect {
      .list {
        &__drop {
          display: block !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          position: static !important;
          top: initial !important;
          left: inherit !important;
          padding: 0 0 0 0 !important;

          &.with-search {
            .search-wrap,
            .search-select {
              transition: height ease $transitionTime;
            }

            .search-wrap {
              padding-left: 0;
              padding-right: 0;

              input {
                width: 100%;
                @media screen and (max-width: 400px) {
                  width: calc(100% - 10px);
                }
              }
            }
          }
        }

        .form-group {
          label {
            padding: 8px 10px 8px 0 !important;
          }

          input {
            &:checked {
              + label {
                &:after {
                  left: 6px !important;
                }
              }
            }
          }
        }

        .radio-item {
          label {
            padding-left: 0 !important;

            &:after {
              left: 5px !important;
            }
          }
        }
      }
    }

    .flatpickr-calendar {
      &.inline {
        display: block !important;
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          height: 0;
          opacity: 0;
          transition: all ease $transitionTime;
          overflow: hidden;
          position: relative;
          left: -10px;
        }
      }

      &.showCalendar {
        .flatpickr-calendar {
          &.inline {
            height: 335px !important;
            opacity: 1;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1420px) {
    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -5px;
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    app-multiselect {
      .list {
        &__drop {
          &.with-search {
            .search-wrap {
              input {
                width: calc(100% - 10px);
              }
            }
          }
        }
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -11px;
        }
      }
    }
  }
}

// 1600
app-patients-docs,
app-admin-dashboard,
app-patients,
app-admission-incomplete-reports-page,
app-medical,
app-incident-accuracy-report,
app-mandatory-attachments-report,
app-timesheets,
app-timesheets-daily-report {
  @media screen and (max-width: 1600px) {
    app-multiselect {
      .list {
        &__drop {
          display: block !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          position: static !important;
          top: initial !important;
          left: inherit !important;
          padding: 0 0 0 0 !important;

          &.with-search {
            .search-wrap,
            .search-select {
              transition: height ease $transitionTime;
            }

            .search-wrap {
              padding-left: 0;
              padding-right: 0;

              input {
                width: 100%;
                @media screen and (max-width: 400px) {
                  width: calc(100% - 10px);
                }
              }
            }
          }
        }

        .form-group {
          label {
            padding: 8px 10px 8px 0 !important;
          }

          input {
            &:checked {
              + label {
                &:after {
                  left: 6px !important;
                }
              }
            }
          }
        }
      }
    }

    .flatpickr-calendar {
      &.inline {
        display: block !important;
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          height: 0;
          opacity: 0;
          transition: all ease $transitionTime;
          overflow: hidden;
          position: relative;
          left: -10px;
        }
      }

      &.showCalendar {
        .flatpickr-calendar {
          &.inline {
            height: 335px !important;
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1420px) {
    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -5px;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    app-multiselect {
      .list {
        &__drop {
          &.with-search {
            .search-wrap {
              input {
                width: calc(100% - 10px);
              }
            }
          }
        }
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -11px;
        }
      }
    }
  }
}

// 1420
app-documents-history,
app-admission-accuracy-reports-page,
app-education,
app-medical-documents-history,
app-timesheets-daily-report,
app-employee-daily-report,
app-timesheets-daily-report {
  @media screen and (max-width: 1420px) {
    app-multiselect {
      .list {
        &__drop {
          display: block !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          position: static !important;
          top: initial !important;
          left: inherit !important;
          padding: 0 0 0 0 !important;

          &.with-search {
            .search-wrap,
            .search-select {
              transition: height ease $transitionTime;
            }

            .search-wrap {
              padding-left: 0;
              padding-right: 0;

              input {
                width: 100%;
                @media screen and (max-width: 400px) {
                  width: calc(100% - 10px);
                }
              }
            }
          }
        }

        .form-group {
          label {
            padding: 8px 10px 8px 0 !important;
          }

          input {
            &:checked {
              + label {
                &:after {
                  left: 6px !important;
                }
              }
            }
          }
        }

        .radio-item {
          label {
            padding-left: 0 !important;

            &:after {
              left: 5px !important;
            }
          }
        }
      }
    }

    .flatpickr-calendar {
      &.inline {
        display: block !important;
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          height: 0;
          opacity: 0;
          transition: all ease $transitionTime;
          overflow: hidden;
          position: relative;
          left: -5px;
        }
      }

      &.showCalendar {
        .flatpickr-calendar {
          &.inline {
            height: 335px !important;
            opacity: 1;
          }
        }
      }
    }
  }
}

// 1200
app-attachments-employee,
app-red-flag-attachments,
app-incidents-attachments,
app-admission-attachments,
app-plan-details,
app-user-management,
app-attachment-medical,
app-mandatory-attachments-report {
  @media screen and (max-width: 1200px) {
    app-multiselect {
      .list {
        &__drop {
          display: block !important;
          box-shadow: none !important;
          border-radius: 0 !important;
          position: static !important;
          top: initial !important;
          left: inherit !important;
          padding: 0 0 0 0 !important;

          &.with-search {
            .search-wrap,
            .search-select {
              transition: height ease $transitionTime;
            }

            .search-wrap {
              padding-left: 0;
              padding-right: 0;

              input {
                width: 100%;
                @media screen and (max-width: 400px) {
                  width: calc(100% - 10px);
                }
              }
            }
          }
        }

        .form-group {
          label {
            padding: 8px 10px 8px 0 !important;
          }
        }

        .radio-item {
          label {
            padding-left: 0 !important;

            &:after {
              left: 5px !important;
            }
          }
        }
      }
    }

    .flatpickr-calendar {
      &.inline {
        display: block !important;
      }
    }

    .by-date {
      .flatpickr-calendar {
        &.inline {
          height: 0;
          opacity: 0;
          transition: all ease $transitionTime;
          overflow: hidden;
          position: relative;
          left: -5px;
        }
      }

      &.showCalendar {
        .flatpickr-calendar {
          &.inline {
            height: 335px !important;
            opacity: 1;
          }
        }
      }
    }
  }
}

app-user-management {
  .list {
    .form-group {
      input {
        &:checked {
          + label {
            &:after {
              @media screen and (max-width: 1200px) {
                left: 6px !important;
              }
            }
          }
        }
      }
    }
  }
}

// 400
app-red-flag-attachments {
  @media screen and (max-width: 400px) {
    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -10px;
        }
      }
    }
  }
}

app-incidents,
app-patients-incident-report {
  @media screen and (max-width: 400px) {
    .by-date {
      .flatpickr-calendar {
        &.inline {
          left: -5px !important;
        }
      }
    }
  }
}
