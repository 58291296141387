@import "../../../assets/styles/utils/vars";
@import "../../../assets/styles/utils/mixins";

.hide-datatable-header {
  .datatable-header {
    display: none !important;
  }
}

.datatable-row-left,
.datatable-row-center,
.datatable-row-right {
  transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  will-change: translate;
}

.datatable-row-right {
  &.datatable-row-group {
    backface-visibility: visible !important;
  }
}

.admin-table {
  box-shadow: $box-shadow-table;
  position: relative;

  &.mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }

  &__head {
    display: grid;
    grid-template-columns: 30% 52% 18%;
    align-items: center;
    width: 100%;
    min-height: 34px;
    background-color: $white-color;
    padding: 0 14px 0 20px;
    border-bottom: 1px solid #e8e9f4;
    @media screen and (max-width: 915px) {
      grid-template-columns: 30% 41% 29%;
    }
    @media screen and (max-width: 767px) {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr;
      padding-bottom: 5px;
      padding-left: 10px;
    }

    .grid-main-table {
      &__manage {
        justify-content: flex-end;
      }
    }

    &.with-ma {
      .tabs {
        .part-tabs {
          padding-right: 10px;
          padding-left: 10px;

          &.no-active {
            padding-left: 20px;
            background: #F9F9FC;
            border-right: 1px solid #e8e9f4;
          }

          &.active {
            padding-left: 20px;
            background: #FFFFFF;
            border-right: 1px solid #e8e9f4;
          }
        }

      }
    }

    .tabs {
      @media screen and (max-width: 767px) {
        grid-column: 2;
        grid-row: 2;
      }
      @media screen and (max-width: 500px) {
        grid-column: 1;
      }

      .part-tabs {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 20px;

        height: 100%;
        @media screen and (max-width: 767px) {
          padding-left: 10px;
        }
      }
    }

    .total-info {
      justify-self: end;
      @media screen and (max-width: 767px) {
        justify-self: start;
        grid-column: 1 / 3;
      }
    }

    .total-number {
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.33;
      color: #9d9ea8;
      @media screen and (max-width: 767px) {
        padding: 5px 0 10px 0;
        align-self: flex-start;
      }
    }

    .manage-mobile {
      display: flex;
      padding-bottom: 10px;
    }

    .per-page-info {
      justify-self: end;
      @media screen and (max-width: 767px) {
        grid-column: 1;
        grid-row: 2;
        justify-self: start;
      }
      @media screen and (max-width: 500px) {
        grid-row: 3;
        margin-top: 10px;
        justify-self: end;
      }

      &.hide-per-page {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    .selected-panel {
      font-family: $openSansSemiBold;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.33;
      color: #9d9ea8;
      flex-shrink: 0;

      &-label {
        display: inline-block;
        margin-right: 20px;
        @media screen and (max-width: 767px) {
          margin-right: 10px;
        }

        .icon-close {
          margin-right: 23px;
          font-size: 11px;
          font-family: $openSansBold;
          @media screen and (max-width: 767px) {
            margin-right: 5px;
          }
        }
      }

      .count {
        color: $green-color;
        font-family: $openSansBold;
        margin-left: 4px;
      }

      .select-btn {
        font-size: 12px;
        color: $violet;
        position: relative;
        margin-right: 20px;
        font-family: $openSansRegular;
      }

      .delete-btn, {
        font-size: 12px;
        color: $violet;
        position: relative;
        padding-left: 17px;
        font-family: $openSansRegular;

        .icon-delete {
          position: absolute;
          left: 0;
          top: 49%;
          transform: translateY(-50%);
        }

        .text-delete {
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }
    }

    .manage {
      display: flex;
      align-items: center;
      font-family: $openSansSemiBold;
      font-weight: normal;
      @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: space-between;
      }

      .list {
        &__drop {
          @media screen and (max-width: 1200px) {
            position: absolute;
            z-index: 10;
            top: calc(100% + 7px);
            left: -33px;
            min-width: 158px;
            border-radius: 3px;
            box-shadow: $drop-box-shadow;
            background-color: $white-color;
            border: none;
            display: none;
          }
          @media screen and (max-width: 767px) {
            left: inherit;
            right: 0;
          }
        }
      }

      &__per-page {
        font-size: 12px;
        line-height: 1.33;
        color: #9d9ea8;

        label {
          display: inline-block;
          margin-left: 6px;
          position: relative;
          @media screen and (max-width: 767px) {
            margin-left: 3px;
          }

          i {
            position: absolute;
            right: 7px;
            top: 9px;
            font-size: 6px;
            color: #cfd0de;
          }

          select {
            display: inline-block;
            cursor: pointer;
            width: 57px;
            height: 22px;
            border-radius: 3px;
            border: 1px solid #cfd0de;
            background: $white-color;
            padding: 0 20px 0 8px;
            font-family: $openSansSemiBold;
            font-size: 12px;
            line-height: 1.33;
            color: #9d9ea8;
            font-weight: normal;
            transition: border-color ease 0.15s;
            @media screen and (max-width: 330px) {
              width: 49px;
            }

            &:focus {
              border-color: $violet-hover;
            }
          }
        }
      }

      &__columns {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: normal;
        line-height: 1.33;
        color: #4c5560;
        margin-left: 34px;
        position: relative;

        .settings {
          color: #4c5560;
          display: flex;
          align-items: center;
          transition: opacity ease .15s;

          &-label {
            font-family: $openSansSemiBold;
            font-size: 12px;
            line-height: 1.33;
            color: #9d9ea8;
            @include breakpoint(mobile-m) {
              display: none;
            }

            &--selected {
              @media screen and (max-width: 767px) {
                display: none;
              }
            }
          }

          &:hover {
            opacity: 1;
          }
        }

        i {
          display: inline-block;
          font-size: 15px;
          margin-left: 6px;
          position: relative;
          top: 0;
          color: #9d9ea8;
        }
      }

      &.hide-mobile {
        justify-content: flex-end;

        .manage {
          &__per-page {
            @include breakpoint(mobile-m) {
              display: none;
            }
          }
        }
      }
    }

    &.selected-items {
      display: flex !important;
      justify-content: space-between !important;
    }

    &.grid-main-table {
      grid-template-columns: 50% 50%;
      @media screen and (max-width: 767px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        padding-bottom: 5px;
      }
    }
  }
}

.ngx-datatable {
  background: linear-gradient(to bottom, $white-color 50%, #f8f8fb 50%) 0 -10px;
  background-size: 100% 88px;

  input {
    text-overflow: ellipsis;
    border: none;
    background: transparent;
  }

  .datatable-row-odd {
    background-color: $white-color;
  }

  .datatable-row-even {
    background-color: #f8f8fb;
  }

  .datatable-body-cell,
  .datatable-header-cell {
    height: 44px !important;
  }

  .datatable-header {
    background-color: $white-color;
    align-items: center;
    font-family: $openSansSemiBold;
    font-size: 11px;
    font-weight: normal;
    line-height: 1.27;
    color: #9d9ea8;
    width: 100% !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .datatable-header-inner {
      align-items: center;

      .datatable-row-left,
      .datatable-row-center,
      .datatable-row-right {
        height: 34px !important;
        align-items: center;
        display: flex;
        will-change: transform !important;
      }

      .datatable-row-center {
        width: 100% !important;

        .datatable-header-cell {
          &:first-child {
            .datatable-header-cell-template-wrap {
              padding-left: 20px;
            }
          }
        }
      }
    }

    .datatable-header-cell,
    .datatable-header-cell-template-wrap {
      display: flex;
      align-items: center;
    }

    .datatable-header-cell {
      .datatable-header-cell-template-wrap {
        padding-left: 10px;
        padding-right: 5px;
      }

      &.center {
        .datatable-header-cell-template-wrap {
          width: 100%;
          justify-content: center;
          padding-left: 5px;
          padding-right: 5px;
        }
      }

      &.cursor-initial {
        pointer-events: none !important;

        .datatable-header-cell-label {
          cursor: inherit;
        }
      }
    }
  }

  .datatable-body {
    font-family: $openSansSemiBold;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.42;
    color: #4c5560;
    width: 100% !important;

    .datatable-body-row {
      align-items: center;

      > div {
        height: 44px;
        align-items: center;
      }

      .datatable-row-center {
        .datatable-body-cell {
          &:first-child {
            .datatable-body-cell-label {
              padding-left: 20px;
            }
          }
        }
      }

      .datatable-body-cell {
        &.center {
          .datatable-body-cell-label {
            padding-left: 5px;
            padding-right: 5px;
            display: flex;
            justify-content: center;
            width: 100%;
          }

          .action-doc,
          .action-btn-row,
          .actions-btn {
            justify-content: center;
          }
        }
      }

      &.active {
        background-color: #E7E8F8;

        .datatable-row-center,
        .datatable-row-right {
          background-color: #E7E8F8 !important;
        }
      }
    }

    .datatable-body-cell-label {
      padding: 3px 5px 3px 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .datatable-row-wrapper {
      &:nth-child(odd) {
        .datatable-body-row {
          background-color: #f8f8fb;
        }
      }

      &:nth-child(even) {
        .datatable-body-row {
          background-color: $white-color;
        }
      }
    }
  }

  .status-color {
    display: flex;
    align-items: center;

    .color {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: inline-block;
      flex-shrink: 0;
    }

    .text {
      display: block;
      padding-left: 5px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .datatable-header-cell-wrapper {
    max-width: 100%;
    display: block;
  }

  .datatable-header-cell-label {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-transform: capitalize;
  }

  &.scroll-horz {
    .datatable-body {
      @include scrollbar($scrollBarColor, transparent);
    }
  }

  &.scroll-vertical {
    .datatable-body {
      overflow-y: auto !important;
    }
  }
}

.pager-wrapper {
  padding-top: 30px;
}

// custom templates
.table-checkbox {
  display: flex;
  width: 100%;
  justify-content: center;

  label {
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #c8c8de;
      border-radius: 3px;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked {
      + label {
        &:before {
          background-color: #e7e8f8;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 7px;
          width: 6px;
          height: 14px;
          border: solid #0079bf;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}

.matrixCare {
  &__link {
    display: block;
    text-align: center;
    text-decoration: underline;
    color: $violet;
    transition: $transition;
    height: 15px;

    &:hover {
      color: $violet-hover;
    }

    img {
      height: 100%;
    }
  }
}

.action-doc,
.action-btn-row,
.actions-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  button {
    color: $violet;
    transition: $transition;
    font-size: 15px;
    display: inline-flex;
    align-items: center;

    &:hover {
      color: $violet-hover;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.user-management,
.categories-dashboard,
.admin-dashboard,
.documents-history {
  // todo: remove after migrating to datatable component
  .ngx-datatable {
    .datatable-header {
      .datatable-header-inner {
        .datatable-row-left {
          background-color: $white-color;
          box-shadow: 8px 0 12px 0 rgba(46, 55, 65, 0.1);
        }

        .datatable-row-right {
          position: fixed !important;
          transform: none !important;
          right: 0;
          box-shadow: -8px 0 12px 0 rgba(46, 55, 65, 0.1);
        }

        .datatable-row-right {
          background-color: $white-color;
          position: relative;

          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 250px;
            top: 0;
            bottom: 0;
            background-color: $white-color;
          }
        }
      }
    }

    .datatable-body {
      .datatable-row-left {
        background-color: $white-color;
        box-shadow: 8px 0 12px 0 rgba(46, 55, 65, 0.1);
      }
    }

    .datatable-row-even {
      .datatable-row-left {
        &.datatable-row-group {
          background-color: #f8f8fb;
          box-shadow: 8px 0 12px 0 rgba(46, 55, 65, 0.1);
        }
      }

      .datatable-row-right {
        &.datatable-row-group {
          background-color: #f8f8fb;
          box-shadow: -8px 0 12px 0 rgba(46, 55, 65, 0.1);
        }
      }

      .datatable-row-right {
        &.datatable-row-group {
          background-color: #f8f8fb;
          position: fixed !important;
          right: 0;
          transform: none !important;

          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 250px;
            top: 0;
            bottom: 0;
            background-color: #f8f8fb;
          }
        }
      }
    }

    .datatable-row-center {
      &.datatable-row-group {
        width: 100% !important;
      }
    }

    .datatable-body-row {
      &.datatable-row-odd {
        .datatable-row-left {
          &.datatable-row-group {
            background-color: $white-color;
            box-shadow: 8px 0 12px 0 rgba(46, 55, 65, 0.1);
          }
        }

        .datatable-row-right {
          &.datatable-row-group {
            background-color: $white-color;
            box-shadow: -8px 0 12px 0 rgba(46, 55, 65, 0.1);
            position: fixed;
            transform: none !important;
            right: 0;

            &:after {
              content: '';
              display: block;
              position: absolute;
              left: 250px;
              top: 0;
              bottom: 0;
              background-color: $white-color;
            }
          }
        }
      }
    }
  }
}

.resize-handle {
  &:before {
    content: '';
    background: red;
    width: 1px;
    height: 100px;
  }

  &:after {
    font-family: 'icomoon', sans-serif;
    content: "\e919";
    font-size: 16px;
    color: #4c5560;
    position: absolute;
    top: 10px;
    left: -9px;
  }
}

.text-overflow-ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// todo: remove after migrating to datatable component
.disable-datatable {
  opacity: 0.8 !important;
  pointer-events: none !important;
}
